<template>
  <div class="flex flex-col mb-10">

    <div style="margin-left: -10px; padding:30px; border: 1px solid #8080807a; border-radius: 25px;; border-radius: 5px;">
    
     <div class="md-layout md-gutter">
       <div class="md-layout-item md-size-100 md-xsmall-size-100 md-small-size-100">
        <Content textColor='black' class="my-3 text-uppercase"  text="Number Of Mock Test" />
       </div>
       <div class="md-layout-item md-size-30 md-xsmall-size-30 md-small-size-30">
          <ul>
            <li class="my-2"> <label :class="{'active': 'FULL_MOCK' == activeIndex}" class="text-gray-500 py-1" @click="getQuestionbySection('FULL_MOCK')">Full Mock Test</label></li>
            <li class="my-2"> <label :class="{'active': 'PARTIAL_MOCK' == activeIndex}" class="text-gray-500 py-1" @click="getQuestionbySection('PARTIAL_MOCK')">Partial Mock Test</label></li>
            <li class="my-2"> <label :class="{'active': 'SECTION_WISE_MOCK' == activeIndex}" class="text-gray-500 py-1" @click="getQuestionbySection('SECTION_WISE_MOCK')">Sectionwise Mock Test</label></li>
          </ul>
       </div>
       <div class="md-layout-item md-size-70 md-xsmall-size-70 md-small-size-70" style="padding:30px; border: 1px solid #8080807a; border-radius: 25px;; border-radius: 5px;">
         <div class="md-layout md-gutter">
         <div class="md-layout-item md-size-30 md-xsmall-size-30 md-small-size-30">
          <h4 class="">  {{activeIndex.replace("_", " ")}} 
            <!-- <span class="rounded bg-victoria text-white font-bold px-2 ">{{this.form.mock_test_tests.length}}</span> -->
            </h4>
        </div>
        <div class="md-layout-item md-size-70 md-xsmall-size-70 md-small-size-70">
          <div class="mb-4 flex justify-end flex-wrap justify-xs-center">
            <md-checkbox @change="selectAllQuestionId($event)" v-model="form.mock_test_all_access">All Access</md-checkbox>
            <SearchBox
              placeholder="Search"
              class="w-auto p-2 m-1"
              v-model="keyword"
              @submit="submitSearchKeyword"
              />
          </div>
        </div>
         </div>
        <md-divider></md-divider>
         <Table :items="mockTestList" hover-action>

              <template slot="row" slot-scope="{ item }">
                <md-table-cell md-sort-by="id" @click.native="selectQuestionId(item.id)" md-label="SL" md-numeric>{{ item.sl }}</md-table-cell>
                <md-table-cell md-sort-by="t_id" @click.native="selectQuestionId(item.id)"  md-label="TEST ID" class="text-victoria">{{ item.t_id }}</md-table-cell>
                <md-table-cell md-sort-by="title" @click.native="selectQuestionId(item.id)" md-label="NAME OF MOCK TEST">{{ item.title }}
                  <span v-if="form.mock_test_tests.includes(item.id)" class="absolute" style="right:0">
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
                      <path :fill="'#00df05'" d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M17.7,9.7l-7,7C10.5,16.9,10.3,17,10,17s-0.5-0.1-0.7-0.3l-3-3c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l2.3,2.3l6.3-6.3c0.4-0.4,1-0.4,1.4,0S18.1,9.3,17.7,9.7z"  />
                    </svg>
                  </span>
                </md-table-cell>
              </template>
          </Table>
       </div>
     </div>

              
    </div>   

    <div class="flex justify-end mt-4">
      <!-- <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button> -->
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Next"
          type="button"
          @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Table,
  SearchBox
} from "@/components";

import { mapGetters,mapActions} from 'vuex';
import Content from "@/components/atom/Content";
import { query } from "@/utils/queryString";

export default {
  components: {
    Button,
    Content,
    Table,
    SearchBox
  },

  data() {
    return {
      active: false,
      activeColor:String,
      activeIndex: null,
      loading: false,
      keyword: '',
      selectedKeyword: '',
      tests:[], 
      form: { 
        mock_test_tests:[],
        mock_test: '',
        mock_test_all_access: false
        
      } 
       
    }
  },
  computed:{
   ...mapGetters({
          mockTestList : "testCreation/getPracticeTestList"
      }),
  },
   watch: {
    keyword(oldvalue, newValue) {
        query.set('search', oldvalue)
        // For reseting search result
        if(oldvalue.length < newValue.length && oldvalue.length == 0) {
         this.actPracticeTestList(`?${query.get()}`);
        }
        // For submitting search api after 3 cherecter
        if(oldvalue.length >= 3) {
          this.actPracticeTestList(`?${query.get()}`);
        }
    }
  },
  methods: {
   
    ...mapActions({
        actPracticeTestList: "testCreation/actPracticeTestList"
    }),
    formClear(){
     this.form = {
       mock_test_tests: []
      }
    },

     selectAllQuestionId(id){
     
      if(id){
        this.mockTestList.map((item) => {
          this.form.mock_test_tests.push(item.id);
          this.tests.push(item.id);
        });
      }else{
         for (let i = 0; i < this.form.mock_test_tests.length; i++) {
          this.tests.indexOf(this.form.mock_test_tests[i]) === -1 ?  '': this.tests.splice(this.tests.indexOf(this.form.mock_test_tests[i]),1);
        }
        this.form.mock_test_tests = [];
      }
    },
    selectQuestionId(id){
      this.form.mock_test_tests.indexOf(id) === -1 ? this.form.mock_test_tests.push(id): this.form.mock_test_tests.splice(this.form.mock_test_tests.indexOf(id),1);
      this.tests.indexOf(id) === -1 ? this.tests.push(id): this.tests.splice(this.tests.indexOf(id),1);
    },

    submitSearchKeyword() {
     this.actPracticeTestList(`?${query.get()}`);
    },

    getQuestionbySection(value){
      this.activeIndex = value;
      query.set('type', value)
      this.actPracticeTestList(`?${query.get()}`);
    },   
    submit() {
      
      let formData = this.getLocal('package-create-info');
      this.form.mock_test = this.form.mock_test_tests.length;

      if(this.tests.length > 0){
       formData.tests = [...this.tests];
        formData.package_detail = {
          ...formData.package_detail,
          ...this.form
          
        };
      }
      this.setLocal('package-create-info', formData);
      query.remove('type');
      query.remove('search');

      this.$emit("menu", this.$route.name);
      this.getPropertiesRoute();
    },
    getPropertiesRoute(){
      let packageInfo = this.getLocal("package-create-info"); 
      if(packageInfo && packageInfo.package_detail){

        if(packageInfo.package_detail.is_quiz){
          this.$router.push({ name: 'packageCreation.properties.quiz' });
          return;
        }
        if(packageInfo.package_detail.is_materials){
          this.$router.push({ name: 'packageCreation.properties.materials' });
          return;
        }
        if(packageInfo.package_detail.is_live_class){
          this.$router.push({ name: 'packageCreation.properties.liveClass' });
          return;
        }
        if(packageInfo.package_detail.is_webinars){
          this.$router.push({ name: 'packageCreation.properties.webinars' });
          return;
        }
        if(packageInfo.package_detail.is_one_to_one_appointment){
          this.$router.push({ name: 'packageCreation.properties.OneToOneAppointment' });
          return;
        }

        this.$router.push({ name: 'packageCreation.durationAndPrice' });
      }
    },

    clearAll() {
      this.$emit("clear-all", "");
    }
  },

  created() {

    query.remove('type');
    query.remove('search');

    this.activeIndex = 'FULL_MOCK';

    query.set('type', 'FULL_MOCK')

    this.actPracticeTestList(`?${query.get()}`);
    let existData = this.getLocal('package-create-info');
    if (existData && existData.package_detail && existData.package_detail.mock_test_tests) this.form = existData.package_detail;
    if (existData && existData.tests) this.tests = existData.tests;
  }
}
</script>
<style scoped>

  li label{
      cursor: pointer;
  }
  .active {
    color: black !important;
    font-weight: bold;
  }
</style>